.container {
  display: flex;
  width: 100%;
  flex: 0;
  align-items: center;
  justify-content: center;
  font-family: var(--font-secondary);
  font-size: 1rem;
  font-weight: 500;

  & input {
    display: none;
  }
}

.checkbox {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-neutral-400);
  aspect-ratio: 1;
  cursor: pointer;
  font-size: var(--size);

  & > svg {
    visibility: hidden;
  }

  .input:checked ~ & {
    font-weight: 700;

    & > svg {
      visibility: visible;
    }
  }
}

.square {
  border-radius: 2px;
}

.circle {
  border-radius: 50%;
}

.primary.checked {
  background-color: var(--color-primary-light);
}

.secondary.checked {
  background-color: var(--color-secondary-base);
}

.label {
  margin-left: 8px;
  color: var(--color-neutral-300);
  cursor: pointer;
}

.labelChecked {
  color: var(--color-neutral-0);
  font-weight: 700;
}

.labelDisabled {
  color: var(--color-neutral-400);
  cursor: not-allowed;
  font-weight: 700;
}

.disabled {
  border-color: var(--color-neutral-600);
  background: none;
  color: var(--color-neutral-800);
  cursor: not-allowed;
}

.disabled.checked {
  background-color: var(--color-neutral-500);
  color: var(--color-neutral-800);
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.filled {
  &:focus-within {
    outline: 2px solid var(--color-primary-base);
  }

  &[data-checked='true'] {
    border-color: currentColor;
    color: var(--color-neutral-100);

    .circle {
      border-radius: 50%;
    }

    .square {
      border-radius: 2px;
      background: var(--color-neutral-600);
      transition: all 0.2s ease-in-out;
    }

    .checkMark {
      color: var(--color-neutral-100);
      stroke: var(--color-neutral-100);
      stroke-width: 2px;
    }
  }
}
