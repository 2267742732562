.pageLayout {
  display: grid;
  padding: 1rem;
  margin-bottom: 2rem;
  row-gap: 2em;

  @media (min-width: 48em) {
    padding: 1.5rem 1rem 0 0;
  }

  @media (min-width: 62em) {
    padding: 1.5rem 0 0;
    grid-template-columns: 1fr 27.5rem;
  }
}

.thriveWeekDaysList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidebar {
  background: var(--content-layout-background-color);
  grid-column: 1 / -1;
  grid-row: 1;

  @media (min-width: 62em) {
    position: sticky;
    z-index: var(--z-index-header);
    top: calc(var(--header-height) + 1.75rem);
    height: min-content;
    grid-column: 2;
    padding-inline: 2.5rem;
    transform: translateY(-0.25rem);
  }

  .sectionTitle {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }

  .authorSection {
    .sectionTitle {
      margin-bottom: 1rem;
    }

    .authorCard {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      border-radius: 0.25rem;
      margin-bottom: 1.5rem;
      background: var(--color-neutral-700);
      gap: 0.75rem;

      .authorName {
        margin: 0;
        margin-bottom: 0.25rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
      }

      .authorPosition {
        margin: 0;
        color: var(--color-secondary-base);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
      }
    }
  }

  & p {
    color: var(--color-neutral-300);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
}
