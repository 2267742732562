.button {
  font-family: var(--font-secondary);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;

  & > span {
    display: none;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;

    @media (min-width: 48em) {
      display: block;
    }
  }

  .loadingSpinner {
    flex-shrink: 0;
  }

  & > svg {
    flex-grow: 0;
    flex-shrink: 0;
  }

  & > svg,
  path {
    fill: currentColor;
  }

  [sidebar-state='collapsed'] & span {
    opacity: 0;
  }
}
