.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details {
  box-sizing: content-box;
  border-radius: 0.25rem;
  background: var(--color-neutral-700);
  transition: background 0.2s ease-out;

  &:hover,
  &:focus {
    background: var(--color-neutral-600);
  }
}

.icon {
  color: var(--color-neutral-0);
  transition: transform 0.2s ease-out;

  &[data-is-expanded='true'] {
    transform: rotate(180deg);
  }
}

.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: var(--color-neutral-0);
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  list-style: none;
  outline: none;

  &::-webkit-details-marker {
    display: none;
  }
}

.content {
  padding: 0.5rem 1rem 1rem;
  background: var(--color-neutral-700);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  & > :first-child {
    margin-top: 0;
  }

  & h3 {
    color: var(--color-primary-base);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  & p {
    color: var(--color-neutral-200);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
}
