.groupImage {
  border-radius: 0.25rem;
  background: var(--color-neutral-600);
  object-fit: contain;

  &[data-is-placeholder='true'] {
    padding: 0 0.25rem;
    object-fit: contain;
  }
}
