@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.field {
  &:focus-within {
    .input {
      &[data-variant='outlined'] {
        border-color: var(--color-neutral-200);
      }

      & svg {
        color: var(--color-neutral-200);
      }
    }

    .label {
      color: var(--color-neutral-200);
    }
  }

  &[data-disabled='true'] {
    cursor: not-allowed;

    .input {
      border-color: currentColor;
      color: var(--color-neutral-500);

      &:active {
        outline: none;
      }

      & svg {
        color: var(--color-neutral-500);
      }
    }

    .label {
      color: var(--color-neutral-500);
      cursor: not-allowed;
    }

    .helperText {
      color: var(--color-neutral-500);
    }
  }

  &[data-status='success'] {
    .input[data-variant='outlined'] {
      border-color: var(--color-success);
    }

    .helperText {
      color: var(--color-success);
    }
  }

  &[data-status='error'] {
    animation: shake 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

    .input {
      border-color: var(--color-alert);
    }
  }
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-neutral-300);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}

.field + .field {
  margin-top: 1rem;
}

.field + .inputFieldGroup {
  margin-top: 1rem;
}

.inputFieldGroup + .field {
  margin-top: 1rem;
}

.inputFieldGroup + .inputFieldGroup {
  margin-top: 1rem;
}

.inputFieldGroup {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;

  .field {
    margin-top: 0;
  }
}

.helperText {
  margin: 0.5rem 0 0;
  color: var(--color-neutral-300);
}

.input {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 3.25rem;
  align-items: center;
  padding: 0.875rem 1rem;
  background: transparent;
  gap: 0.75rem;

  &[data-variant='outlined'] {
    border: 0.0625rem solid var(--color-neutral-500);
    border-radius: 0.375rem;
  }

  & input,
  textarea {
    position: absolute;
    width: 100%;
    padding: 1rem;
    border: none;
    -webkit-appearance: none;
    background: none;
    color: var(--color-neutral-100);
    font-family: var(--font-secondary);
    font-size: 1rem;
    font-weight: 500;
    inset: 0;
    line-height: 1.125rem;
    outline: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--color-neutral-300);
    }

    &:active {
      color: var(--color-neutral-200);

      &::placeholder {
        color: currentColor;
      }
    }

    &:disabled {
      color: var(--color-neutral-500);
      cursor: not-allowed;

      &::placeholder {
        color: currentColor;
      }
    }
  }

  & textarea {
    min-height: 5rem;
    resize: none;
  }

  & svg {
    color: var(--color-neutral-300);
  }

  &:active {
    border-color: var(--color-primary-light);
    color: var(--color-neutral-200);
    outline: var(--color-neutral-600) solid 0.1875rem;

    &::placeholder {
      color: currentColor;
    }
  }
}

.error {
  margin: 0.5rem 0 0;
  color: var(--color-alert);
}
