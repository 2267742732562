.suggestionListContent {
  position: relative;
  display: flex;
  overflow: hidden;
  overflow: auto;
  width: 20rem;
  max-height: 18rem;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 0.375rem;
  background: var(--color-neutral-600);
  font-size: 0.9rem;
}

.suggestionList {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.suggestionListItem {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  color: var(--color-neutral-200);
  font-size: 1rem;
  font-weight: 400;
  gap: 0.75rem;
  line-height: 1.5rem;
  text-align: left;

  .suggestionListItemButton {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0.5rem 0;
    gap: inherit;
    text-align: left;

    .titleBox {
      display: flex;
      width: 100%;
      gap: inherit;
      text-align: left;
      .title {
        max-width: 6rem;
      }
    }

    .authorBox {
      display: flex;
      gap: 0.5rem;
    }

    & b {
      font-size: 0.6875rem;
      white-space: nowrap;
    }
  }

  &:not(:last-child):after {
    position: absolute;
    bottom: -0.25rem;
    left: 0;
    width: 100%;
    height: 0.0625rem;
    background: var(--color-neutral-600);
    content: '';
  }

  &:hover,
  &:focus {
    /* TODO: Refactor this once we move to hls() for colors */
    background: #30354481;
  }

  &.isSelected {
    background: var(--color-neutral-800);
  }
}

.createExerciseCTA {
  width: 100%;
  background: inherit;
}
