@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingSpinner {
  display: inline-block;
  overflow: hidden;
  background: none;
}

.spinner {
  width: var(--spinner-size);
  height: var(--spinner-size);
  border: calc(var(--spinner-size) / 12) solid currentColor;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spinner 1s linear infinite;
}

.pageContainer {
  display: flex;
  width: 100%;
  min-height: var(--height);
  align-items: center;
  justify-content: center;
}
