.skeleton {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background: var(--color-neutral-500);
  line-height: 1;

  &:before {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    animation: skeleton-progress 1.2s ease-in-out infinite;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      #80869d78,
      rgba(255, 255, 255, 0)
    );
    content: '';
  }
}

@keyframes skeleton-progress {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
