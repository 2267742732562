.workoutForm {
  display: grid;
  padding: 0.75rem;
  gap: 0.5rem;
  grid-template-columns: 2rem minmax(0, 1fr);

  .workoutFormTimeline {
    position: relative;
    z-index: 1;
    width: 0.0625rem;
    height: calc(100% + 3rem);
    background: var(--color-neutral-500);
    grid-column: 1;
    grid-row: 1 / 4;
    justify-self: center;
  }

  & h2 {
    align-self: center;
    margin: 0;
    font-size: 0.75rem;
  }

  .formErrorBanner {
    grid-column: 1 / -1;
  }

  .titleField {
    display: subgrid;
    padding-bottom: 1rem;
    gap: 0.5rem;
    grid-row: 1;

    .titleInput {
      border: none;
      background: none;
      color: var(--color-neutral-100);
      font-weight: 700;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  .buttonGroup {
    display: flex;
    padding-top: 1.5rem;
    gap: 0.75rem;
    grid-column: 2;
    transition: opacity 0.3s ease-in-out;

    &[data-is-editing='false'] {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
}

.steps {
  display: contents;
  gap: inherit;
  grid: inherit;
}

.prepareStep {
  grid-row: 2;
}

.mainStep {
  grid-row: 3;
}

.extraStep {
  grid-row: 4;
}

.notes {
  grid-row: 5;
}

.prepareStep,
.mainStep,
.extraStep {
  position: relative;
  z-index: 3;
  display: subgrid;
  margin-top: 0.25rem;
  padding-block: 0.5rem;
}

.prepareStep,
.extraStep {
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--color-neutral-500);
    content: '';
    grid-column: 2;
  }
}

.extraStep {
  position: relative;
  display: subgrid;
}

.mainStep {
  display: subgrid;
  padding-block: 1rem;

  .exerciseTypes {
    display: grid;
    padding-right: 1rem;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
  }
}

.richText {
  grid-column: 2;
}

.notes {
  padding-top: 1rem;
  grid-column: 2;

  &::placeholder {
    font-size: 0.75rem;
  }
}
