.videoPlaceholder {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  aspect-ratio: 16 / 9;

  &[data-background='dark'] {
    background: var(--color-neutral-800);

    & svg {
      color: var(--color-neutral-500);
    }
  }

  &[data-background='light'] {
    background: var(--color-neutral-600);

    & svg {
      color: var(--color-neutral-300);
    }
  }
}

.video {
  width: 100%;
  padding-top: 0.25rem;
  border-radius: 0.5rem;
  animation-duration: 1s;
  contain: layout;
  object-fit: cover;
}

.video.fullscreen {
  width: 100vw;
  height: 100vh;
  /* no cropping in fullscreen mode */
  object-fit: contain;
}

.videoThumbnail {
  border-radius: 0.5rem;
  animation-duration: 1s;
  contain: layout;
  object-fit: cover;
}
